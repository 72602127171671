/* eslint-disable jsx-a11y/anchor-has-content */
import React from 'react';
import styled from 'styled-components';
import { Link, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import PortableText from 'react-portable-text';
import SEO from '../components/layout/SEO';
import Breadcrumb from '../components/layout/Breadcrumb';
import SidebarContact from '../components/widgets/sidebarContact';

const FaqStyles = styled.div`
  .content {
    padding: 60px 0 0 0;
    display: grid;
    grid-template-columns: 3.5fr 1fr;
    grid-gap: 50px;
  }

  @media only screen and (max-width: 780px) {
    .content {
      grid-template-columns: 1fr;
      grid-auto-flow: dense;
    }
  }

  .hr {
    width: 10%;
    background-color: var(--accent);
    height: 2px;
    margin-bottom: 30px;
  }

  .main-content {
    h2 {
      margin-top: 0;
    }
    p,
    li {
      border-bottom-color: rgb(102, 102, 102);
      border-bottom-style: none;
      border-bottom-width: 0px;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-left-color: rgb(102, 102, 102);
      border-left-style: none;
      border-left-width: 0px;
      border-right-color: rgb(102, 102, 102);
      border-right-style: none;
      border-right-width: 0px;
      border-top-color: rgb(102, 102, 102);
      border-top-style: none;
      border-top-width: 0px;
      box-sizing: border-box;
      color: rgb(102, 102, 102);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      font-feature-settings: normal;
      font-kerning: auto;
      font-language-override: normal;
      font-optical-sizing: auto;
      font-size: 16px;
      font-size-adjust: none;
      font-stretch: 100%;
      font-style: normal;
      font-variant: normal;
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: normal;
      font-variant-position: normal;
      font-variation-settings: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 27px;
      outline-color: rgb(102, 102, 102);
      outline-style: none;
      outline-width: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      text-rendering: optimizelegibility;
      vertical-align: baseline;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  .question-list {
    ul {
      margin: 0;
      list-style: none;
      padding: 0;
    }

    li {
      padding: 0;
      margin: 0;
      border-bottom-color: rgb(102, 102, 102);
      border-bottom-style: none;
      border-bottom-width: 0px;
      border-image-outset: 0;
      border-image-repeat: stretch;
      border-image-slice: 100%;
      border-image-source: none;
      border-image-width: 1;
      border-left-color: rgb(102, 102, 102);
      border-left-style: none;
      border-left-width: 0px;
      border-right-color: rgb(102, 102, 102);
      border-right-style: none;
      border-right-width: 0px;
      border-top-color: rgb(102, 102, 102);
      border-top-style: none;
      border-top-width: 0px;
      box-sizing: border-box;
      color: rgb(102, 102, 102);
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
      font-feature-settings: normal;
      font-kerning: auto;
      font-language-override: normal;
      font-optical-sizing: auto;
      font-size: 16px;
      font-size-adjust: none;
      font-stretch: 100%;
      font-style: normal;
      font-variant: normal;
      font-variant-alternates: normal;
      font-variant-caps: normal;
      font-variant-east-asian: normal;
      font-variant-ligatures: normal;
      font-variant-numeric: normal;
      font-variant-position: normal;
      font-variation-settings: normal;
      font-weight: 400;
      line-height: 28px;
      margin-bottom: 0px;
      margin-left: 0px;
      margin-right: 0px;
      margin-top: 27px;
      outline-color: rgb(102, 102, 102);
      outline-style: none;
      outline-width: 0px;
      padding-bottom: 0px;
      padding-left: 0px;
      padding-right: 0px;
      padding-top: 0px;
      text-rendering: optimizelegibility;
      vertical-align: baseline;
      -moz-osx-font-smoothing: grayscale;
    }

    .q {
      font-size: 20px;
      font-weight: 700;
      color: var(--accent);
      margin-right: 10px;
    }

    a {
      padding: 10px 0;
      display: inline-block;
    }
  }

  .faqs {
    padding: 60px 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
  }

  .faq {
    width: 100%;

    .big {
      font-size: 54px;
      border-left: 5px solid;
      padding: 15px;
    }

    .question {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 15px;
    }

    .faq-question {
      display: flex;
      background: whitesmoke;
      h6 {
        font-size: 16px;
        margin: 0;
        color: var(-heading);
      }
      .big {
        border-color: var(--accent);
        color: var(--accent);
      }
    }
  }

  .faq-answer {
    display: flex;
    background: white;
    h6 {
      font-size: 16px;
      margin: 0;
      color: var(-heading);
    }
    .big {
      border-color: #999;
      color: #999;
    }
  }

  .sidebar {
    p {
      margin: 0;
    }
  }
`;

export default function AboutPage({ data, location }) {
  const pageName = 'Frequently Asked Questions';

  return (
    <FaqStyles>
      <SEO title={pageName} location={location.href} />
      <Breadcrumb title={pageName}>
        <li>
          <Link to="/">Home</Link>
        </li>

        <li>
          <Link to="/faqs">Frequently Asked Questions</Link>
        </li>
      </Breadcrumb>

      <div className="container content flex">
        <div className="main-content">
          <h2>{pageName}</h2>
          <div className="hr" />
          <div className="question-list">
            <ul>
              {data.faqs.nodes.map((faq) => (
                <li key={faq.id}>
                  <span className="q">Q: </span>
                  <a href={`#${faq.id}`}>{faq.question}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="sidebar">
          <SidebarContact />
        </div>
      </div>

      <div className="container">
        <div className="faqs">
          {data.faqs.nodes.map((faq) => (
            <div key={`1234${faq.id}`} className="faq">
              <a id={faq.id} />
              <div className="faq-question">
                <div className="big">
                  <span>Q.</span>
                </div>
                <div className="question">
                  <h6>{faq.question}</h6>
                </div>
              </div>

              <div className="faq-answer">
                <div className="big">
                  <span>A.</span>
                </div>
                <div className="question">
                  <PortableText content={faq._rawAnswer} />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </FaqStyles>
  );
}

export const query = graphql`
  {
    faqs: allSanityFaqs {
      nodes {
        id
        question
        _rawAnswer
      }
    }
  }
`;

AboutPage.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};
